import * as React from "react";
import { Card } from "../components";
import styled from "styled-components";
import { TbSchool, TbBallBaseball, TbBallAmericanFootball, TbGavel, TbLink, TbVideo, TbMapPin } from "react-icons/tb";
import { RiMickeyLine, RiBoxingLine } from "react-icons/ri";
import { GiWeightLiftingUp } from "react-icons/gi";
import { MdOutlineManageSearch, MdOutlineDraw } from "react-icons/md";
import { StaticImage } from "gatsby-plugin-image";

const about = [
  {
    title: "Education",
    text: "BA in Media Studies (Production) & Psychology",
    subtext: "University of Buffalo",
    icon: TbSchool,
    backgroundColor: "#d4e4ee",
  },
  { superText: "content creator specializing in video production", backgroundColor: "#58535b" },
  { title: "Resume", icon: TbLink, backgroundColor: "#d4e4ee", download: "/files/resume.pdf" },
  { showImage: <StaticImage src="../images/hiking.jpeg" /> },
  {
    title: "Currently in...",
    icon: TbMapPin,
    className: "icon",
    biggerIcon: "120px",
    centeredText: "Philadelphia, PA",
    backgroundColor: "#e9ae62",
  },
  { showImage: <StaticImage src="../images/withdog.png" /> },
  [
    {
      title: "Experience",
      icon: TbBallAmericanFootball,
      text: "Live Events Producer @ Philadelphia Eagles",
      subtext: "Jan. 2023 - Present",
      className: "icon",
    },
    {
      title: "Experience",
      icon: TbBallBaseball,
      text: "Video Production Manager @ SWB Railriders",
      subtext: "Jan. 2022 - Dec. 2022",
      className: "icon",
    },
    {
      title: "Experience",
      icon: TbBallAmericanFootball,
      text: "Production Intern @ NY Giants",
      subtext: "Aug. 2021 - Dec. 2021",
      className: "icon",
    },
    {
      title: "Experience",
      icon: TbBallBaseball,
      text: "Video Production Asst. @ Bing Rumble Ponies",
      subtext: "Apr. 2021 - Aug. 2021",
      className: "icon",
    },
    {
      title: "Experience",
      icon: RiBoxingLine,
      text: "Production  @ Rumble Boxing",
      subtext: "Feb. 2021 - Apr. 2021",
      className: "icon",
    },
    {
      title: "Experience",
      icon: TbGavel,
      text: "Social Media & Video Production Intern @ Pardalis & Nohavicka, LLP",
      subtext: "Sep. 2020 - Apr. 2021",
      className: "icon",
    },
    {
      title: "Experience",
      icon: GiWeightLiftingUp,
      text: "Digital Media Intern @ Trooper Fitness",
      subtext: "Dec. 2020 - Mar. 2021",
      className: "icon",
    },
    {
      title: "Experience",
      icon: RiMickeyLine,
      text: "Photopass Photographer @ Walt Disney",
      subtext: "Aug. 2019 - Jan. 2020",
      className: "icon",
    },
  ],
  { showImage: <StaticImage src="../images/withgiants.png" /> },
  [
    {
      title: "Skills",
      text: "Daktronics, Broadcast Pix, Tri-Caster, NewTek 3 Play Replay, Chyron Click Affects Audio, Launchpad Motion Rocket, ProPresenter, Sony PWS-4500, NFL Redzone, NFL GSIS",
      subtext: "Production",
    },
    {
      title: "Skills",
      text: "Adobe Premier Pro, After Effects",
      subtext: "Video",
      icon: TbVideo,
      className: "icon",
    },
    {
      title: "Skills",
      text: "Canva, Illustrator, Photoshop",
      subtext: "Graphics",
      icon: MdOutlineDraw,
      className: "icon",
    },
    {
      title: "Skills",
      text: "Hootsuite, Asana, Airtable",
      subtext: "Content Management",
      icon: MdOutlineManageSearch,
      className: "icon",
    },
  ],
];

const AboutPage = () => {
  return (
    <div className="container">
      <h1>About</h1>
      <p className="center">
        Hi, I'm Taylor! I enjoy staying active by hiking, taking long walks through Manhattan, playing softball,
        swimming and refusing to wake up early to work on my squat, bench, and deadlift at the gym. I also enjoy indie
        horror films, love to cook, and making progress on catching 'em all in Pokemon Diamond. I recently adopted a
        pitbull mix named Peanut Butter who has been my whole world and love taking weekend naps with her. Find out more
        about me below!
      </p>
      <CardContainer>
        {about.map((element, i) => (
          <Card element={element} key={`card-${i}`} />
        ))}
      </CardContainer>
    </div>
  );
};

export default AboutPage;

export const Head = () => <title>TMOY || About</title>;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 900px;

  p {
    margin: 0 0 10px 0;
    max-width: 1000px;
  }
`;
